import { FC } from 'react';

import { Icon } from '@common/elements/Icon';

import { Props as TagIntroBlockProps, TagIntroBlockType } from './TagIntroBlock';
import { TagLinkWrapper } from './TagLinkWrapper';

import styles from './TagIntroBlock.module.scss';

const TeamDrivers: FC<TagIntroBlockProps> = ({ data, ...props }) => {
    const mainDriverContent = data?.mainDriver ? (
        <p className={styles['driver-name']}>{data.mainDriver.toString()}</p>
    ) : (
        <></>
    );
    const secondDriverContent = data?.secondDriver ? (
        <p className={styles['driver-name']}>{data.secondDriver.toString()}</p>
    ) : (
        <></>
    );

    return (
        <>
            {data?.mainDriverTagSlug ? (
                <TagLinkWrapper
                    {...props}
                    type={TagIntroBlockType.DRIVER}
                    linkTagSlug={data.mainDriverTagSlug.toString()}
                >
                    {mainDriverContent}
                </TagLinkWrapper>
            ) : (
                mainDriverContent
            )}

            {data?.secondDriverTagSlug ? (
                <TagLinkWrapper
                    {...props}
                    type={TagIntroBlockType.DRIVER}
                    linkTagSlug={data.secondDriverTagSlug.toString()}
                >
                    {secondDriverContent}
                </TagLinkWrapper>
            ) : (
                secondDriverContent
            )}
        </>
    );
};

export const TeamsInfo: FC<TagIntroBlockProps> = (props) => {
    return (
        <span className={styles['driver-rank']}>
            {props.isMiniView && props.data && (
                <div>
                    <span className={styles['team-drivers']}>
                        <TeamDrivers data={props.data} {...props} />
                    </span>
                    <hr />
                </div>
            )}
            <TagLinkWrapper {...props}>
                <span className={`tag counter ${props.data?.rank === 1 ? 'highlighted' : ''}`}>
                    {props.data?.rank?.toString()}
                </span>
                <p>
                    <>{props.data?.points} PTS</>
                </p>
            </TagLinkWrapper>
            {!props.isMiniView && props.data && (
                <span className={styles['team-drivers']}>
                    <TeamDrivers data={props.data} {...props} />
                </span>
            )}
            {props.linkTagSlug && (
                <TagLinkWrapper {...props}>
                    <span className={styles['go-to-driver']}>
                        <Icon.arrowsRight />
                    </span>
                </TagLinkWrapper>
            )}
        </span>
    );
};
