import { FC } from 'react';
import Image from 'next/image';

import { TagIntroBlockType } from './TagIntroBlock';
import { Props, TagLinkWrapper } from './TagLinkWrapper';

import styles from './TagIntroBlock.module.scss';

interface DriverImageProps {
    props: Props;
    imageThumb: string;
    teamName?: string;
    teamNameSlug?: string;
}

export const Thumbnail: FC<DriverImageProps> = ({ imageThumb, teamName, teamNameSlug, props }) => (
    <div className={styles['thumbnail-container']}>
        <TagLinkWrapper {...props}>
            <Image alt={teamName || ''} width="260" height="260" src={imageThumb} loader={({ src }) => src} />
        </TagLinkWrapper>
        {teamName &&
            ((teamNameSlug as string) ? (
                <TagLinkWrapper {...props} type={TagIntroBlockType.TEAM} linkTagSlug={teamNameSlug as string}>
                    <span className={styles['team-name-tag']}>{teamName}</span>
                </TagLinkWrapper>
            ) : (
                <span className={styles['team-name-tag']}>{teamName}</span>
            ))}
    </div>
);
