export type WebpWidth = WebpWidthEnum | ThumbnailWidth | BackgroundWidth;

export enum WebpWidthEnum {
    WIDTH_1800 = 1800,
    WIDTH_1200 = 1200,
    WIDTH_900 = 900,
    WIDTH_450 = 450,
    WIDTH_225 = 225,
    WIDTH_110 = 110,
}

export enum ThumbnailWidth {
    WIDTH_225 = 225,
    WIDTH_110 = 110,
}

export enum BackgroundWidth {
    WIDTH_1200 = 1200,
    WIDTH_900 = 900,
    WIDTH_450 = 450,
}

export const webpWidths: WebpWidth[] = [
    ...(Object.values(WebpWidthEnum) as WebpWidth[]),
    ...(Object.values(ThumbnailWidth) as WebpWidth[]),
    ...(Object.values(BackgroundWidth) as WebpWidth[]),
].filter((item) => !isNaN(Number(item))) as WebpWidth[];

export const uniqueWebpWidths: WebpWidth[] = [...new Set(webpWidths)];
