import { FC, ReactElement, ReactNode, useMemo } from 'react';
import Image from 'next/image';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';

import { Icon } from '@common/elements/Icon';
import { BackgroundWidth, ThumbnailWidth } from '@common/types/WebpWidth';
import { Winner } from '@web/atoms/racing/types';
import { DetailsBlock } from '@web/molecules/DetailsBlock';
import { Columns, Row } from '@web/molecules/DetailsBlock/types';
import { TagBody } from '@web/molecules/TagIntroBlock/TagBody';
import { TrackImage } from '@web/molecules/TagIntroBlock/TrackImage';

import { Schedule } from '../GrandPrixSchedule';
import * as coureurPlaceholder from './gp-coureur-placeholder.png';
import { Thumbnail } from './Thumbnail';
import { TopHalf } from './TopHalf';

import styles from './TagIntroBlock.module.scss';

export interface TagDataType {
    [key: string]: string | ReactNode | Schedule[] | Winner[];
}

export enum TagIntroBlockType {
    AUTHOR = 'author',
    COMMON = 'common',
    DRIVER = 'driver',
    GRAND_PRIX = 'grand-prix',
    TEAM = 'team',
}

export interface Props {
    type: TagIntroBlockType;
    title: string | ReactElement;
    tag?: string;
    description?: string;
    thumbnail?: string;
    backgroundImage?: string;
    columns?: Columns;
    data?: TagDataType;
    isMiniView?: boolean;
    hasTagOutro?: boolean;
    linkTagSlug?: string;
    isListMember?: boolean;
    __translate?: Translate;
}

const replaceNewLine = (str: string) => (str?.length > 0 ? str.replace(/\\n+/g, '\n') : '');

export const TagIntroBlock: FC<Props> = (props) => {
    const hasTagBody = (props: Props): boolean => Boolean(props.columns || props.description);

    const getAlternativeColor = (props: Props): string => {
        if (props.data?.isPastRace) return '#414448';
        if (props.data?.teamColor) return props.data?.teamColor as string;
        return '';
    };

    const classes = ['inverted', 'tagIntroBlock', styles.TagIntroBlock, styles[`${props.type}-type`]];
    if (props.isMiniView) classes.push(styles['is-mini-view']);
    if (!props.hasTagOutro) classes.push(styles['no-tag-outro']);
    if (props.isListMember) classes.push(styles['is-in-list']);

    const alternativeColor = getAlternativeColor(props);
    // FIXME: We'll use TagImage in https://pxr-tech.atlassian.net/browse/PB-5161
    const imageContains = /webp\./;
    const thumbnailCheck = imageContains.test(props.thumbnail || '');
    let imageThumb = props.thumbnail;
    if (thumbnailCheck)
        imageThumb = props.thumbnail
            ? props.thumbnail.replace('{WebpWidth}', `${ThumbnailWidth.WIDTH_225}`)
            : '';

    // show placeholder when no image thumb is available
    if (!imageThumb && props.type === 'driver') imageThumb = coureurPlaceholder.default.src;

    const backgroundCheck = imageContains.test(props.backgroundImage || '');
    let imageBackground = props.backgroundImage;
    if (backgroundCheck)
        imageBackground = props.backgroundImage
            ? props.backgroundImage.replace('{WebpWidth}', `${BackgroundWidth.WIDTH_1200}`)
            : '';

    const {
        borderTopColor,
        borderBottomColor,
        backgroundColor,
        hasTagOutro,
        isCommonType,
        isGrandPrixType,
        teamName,
        teamNameSlug,
        authorFunction,
        authorSlug,
        titleHasSpaces,
        titleIsString,
    } = useMemo(
        () => ({
            backgroundColor: alternativeColor ? (alternativeColor as string) : '',
            borderBottomColor: alternativeColor ? (alternativeColor as string) : '',
            borderTopColor: alternativeColor ? (alternativeColor as string) : '',
            hasTagOutro: props?.hasTagOutro,
            isCommonType: props?.type === TagIntroBlockType.COMMON,
            isAuthorType: props?.type === TagIntroBlockType.AUTHOR,
            isDriverType: props?.type === TagIntroBlockType.DRIVER,
            isGrandPrixType: props?.type === TagIntroBlockType.GRAND_PRIX,
            isTeamType: props?.type === TagIntroBlockType.TEAM,
            teamName: props?.data?.teamName?.toString(),
            teamNameSlug: props?.data?.teamNameSlug?.toString(),
            authorFunction: props?.data?.function?.toString(),
            authorSlug: props?.data?.authorSlug?.toString(),
            titleHasSpaces: /\s/.test(props.title as string),
            titleIsString: typeof props?.title === 'string',
        }),
        [props, alternativeColor],
    );
    const titleString = useMemo(() => props?.title as string, [props.title]);
    const __meta = useTranslation('meta').t;

    const readAllButton = (
        <button className={styles['read-more']}>
            {__meta`read-all`} {<Icon.arrowsRight />}
        </button>
    );

    return (
        <div className={classes.join(' ')} style={{ borderTopColor }}>
            {imageBackground && (
                <Image
                    alt="tag-background-image"
                    className={styles['background-image']}
                    width="1560"
                    height="344"
                    src={imageBackground}
                    loader={({ src }) => src}
                />
            )}
            <header>
                <>
                    <div className={styles['header-container']}>
                        <TopHalf
                            {...props}
                            titleString={titleString}
                            titleHasSpaces={titleHasSpaces}
                            titleIsString={titleIsString}
                            tag={props.tag}
                            type={props.type}
                            backgroundColor={backgroundColor}
                            borderBottomColor={borderBottomColor}
                        >
                            {isGrandPrixType && <TrackImage imageThumb={imageThumb || ''} />}
                            {!isGrandPrixType && imageThumb && (
                                <Thumbnail
                                    imageThumb={imageThumb}
                                    props={props}
                                    teamName={teamName ? teamName : authorFunction ? authorFunction : ''}
                                    teamNameSlug={teamNameSlug ? teamNameSlug : authorSlug ? authorSlug : ''}
                                />
                            )}
                        </TopHalf>

                        <TagBody
                            props={props}
                            type={props.type}
                            hasTagBody={hasTagBody(props)}
                            isMiniView
                            data={props.data}
                            description={props.description}
                            readAllButton={readAllButton}
                        />
                    </div>
                    {Boolean(!isGrandPrixType && imageThumb) && (
                        <Thumbnail
                            imageThumb={imageThumb || ''}
                            props={props}
                            teamName={teamName ? teamName : authorFunction ? authorFunction : ''}
                            teamNameSlug={teamNameSlug ? teamNameSlug : authorSlug ? authorSlug : ''}
                        />
                    )}
                </>
            </header>

            {Boolean(props?.description || props?.columns) ? (
                <div className={styles['tag-body']}>
                    {Boolean(isCommonType && props.columns) ? <h3>info</h3> : null}
                    {props.columns && (
                        <DetailsBlock columns={props.columns} data={(props.data as Row) || {}} />
                    )}
                    {props?.description ? (
                        <div className={styles.description}>
                            {props.description && (
                                <div
                                    dangerouslySetInnerHTML={{ __html: replaceNewLine(props.description) }}
                                />
                            )}
                        </div>
                    ) : null}
                </div>
            ) : null}

            {hasTagOutro && (
                <button
                    className={styles['scroll-to-tag-outro']}
                    onClick={() => {
                        const outro = document.getElementById('tag-outro');
                        const position = outro?.getBoundingClientRect();
                        if (position) {
                            window.scrollTo({
                                top: position.top + window.scrollY - window.innerHeight * 0.05,
                                behavior: 'smooth',
                            });
                        }
                    }}
                >
                    <Icon.arrowsDown />
                </button>
            )}
        </div>
    );
};
