import { FC } from 'react';

import { Link } from '@common/elements/Link';
import { Logo } from '@common/elements/logo';

import styles from './SocialLink.module.scss';

type Props = {
    socialNetwork?: string;
    url: string;
    showPlatformLabel?: boolean;
};

export const SocialLink: FC<Props> = ({ socialNetwork, url, showPlatformLabel }) => (
    <Link href={url} className={styles.SocialLink}>
        {url ? (
            socialNetwork === 'twitter' ? (
                <>
                    <Logo.twitter />
                    {showPlatformLabel ? <p>Twitter</p> : null}
                </>
            ) : socialNetwork === 'linkedIn' ? (
                <>
                    <Logo.linkedIn />
                    {showPlatformLabel ? <p>LinkedIn</p> : null}
                </>
            ) : (
                <></>
            )
        ) : (
            <></>
        )}
    </Link>
);
