import { FC } from 'react';

import { Author } from '@common/clients/api';
import { SocialLink } from '@web/atoms/SocialLink';

import styles from './AuthorInfo.module.scss';

interface Props extends Author {
    className?: string;
}

export const AuthorInfo: FC<Props> = ({ linkedin, twitter, className }): JSX.Element => (
    <>
        <div className={`${styles.AuthorInfo} ${className}`}>
            {twitter && <SocialLink socialNetwork={'twitter'} url={twitter} />}
            {linkedin && <SocialLink socialNetwork={'linkedIn'} url={linkedin} />}
        </div>
        <hr />
    </>
);
