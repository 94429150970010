import { FC } from 'react';
import Image from 'next/image';

import styles from './TagIntroBlock.module.scss';

interface TrackImageProps {
    imageThumb: string;
}

export const TrackImage: FC<TrackImageProps> = ({ imageThumb }) => {
    return (
        <div className={styles['trackimage-container']}>
            {imageThumb && (
                <Image
                    alt=""
                    src={imageThumb}
                    width="0"
                    height="0"
                    style={{ width: 'auto', height: '100%' }}
                    loader={({ src }) => src}
                />
            )}
        </div>
    );
};
