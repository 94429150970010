import { FC } from 'react';

import { Icon } from '@common/elements/Icon';

import { TagIntroBlockProps as Props, TagLinkWrapper } from './index';

import styles from './TagIntroBlock.module.scss';

export const DriverInfo: FC<Props> = (props) => (
    <TagLinkWrapper {...props}>
        <span className={styles['driver-rank']}>
            <span className={`tag counter ${props.data?.rank === 1 ? 'highlighted' : ''}`}>
                {props.data?.rank?.toString()}
            </span>
            <p>
                <>{props.data?.points} PTS</>
            </p>
            {props.linkTagSlug && (
                <span className={styles['go-to-driver']}>
                    <Icon.arrowsRight />
                </span>
            )}
        </span>
    </TagLinkWrapper>
);
