import { FC, ReactElement, ReactNode } from 'react';

import { Props as TagIntroBlockProps } from './TagIntroBlock';
import { TagLinkWrapper } from './TagLinkWrapper';

import styles from './TagIntroBlock.module.scss';

interface Props extends TagIntroBlockProps {
    backgroundColor: string;
    borderBottomColor: string;
    teamName?: string;
    teamNameSlug?: string;
    title: string | ReactElement;
    titleHasSpaces?: boolean;
    titleIsString?: boolean;
    titleString: string;
    children?: ReactNode;
}

const HeadingTitle = ({ titleString, borderBottomColor, titleHasSpaces, titleIsString, title }: Props) => (
    <h1 style={{ borderBottom: borderBottomColor }}>
        {Boolean(titleHasSpaces && titleIsString) ? (
            <>
                <i>{titleString.split(' ')[0]}</i>
                {titleString.substring(titleString.indexOf(' '))}
            </>
        ) : (
            title
        )}
    </h1>
);

const InnerTag = ({ tag, backgroundColor }: { tag?: string; backgroundColor: string }) =>
    Boolean(tag?.length) ? (
        <span className={styles['inner-tag-container']} style={{ backgroundColor: backgroundColor || '' }}>
            <h3>{tag}</h3>
        </span>
    ) : null;

export const TopHalf: FC<Props> = (props) => {
    const { backgroundColor, borderBottomColor, tag, type } = props;
    return (
        <div className={styles['header-tophalf']}>
            <TagLinkWrapper {...props}>
                <InnerTag tag={tag} backgroundColor={backgroundColor} />
                <HeadingTitle
                    backgroundColor={backgroundColor}
                    borderBottomColor={borderBottomColor}
                    title={props.title}
                    titleString={props.titleString}
                    type={type}
                />
                {props.children}
            </TagLinkWrapper>
        </div>
    );
};
